"use client";

import BasicPage from "@/components/BasicPage";
import { H3 } from "@/components/Headings";
import Message from "@/components/Message";
import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";

export default function ErrorPage({
  error,
}: { error: Error & { digest?: string } }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <BasicPage>
      <H3 className="mb-4 text-center">{error.name}</H3>
      <Message error>{error.message || JSON.stringify(error, null, 2)}</Message>
    </BasicPage>
  );
}
